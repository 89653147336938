import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import Content from '@/components/Content';

import ProductHeader from '@/components/Product/Header';
import Articles from '@/components/Articles';
import { SimpleImage } from '@/components/Ads';
import SearchBloc from '@/components/SearchBloc';

import { slugify } from '@/utils/url';
import { routes } from '@/utils/routes';

const ProductTemplate = ({
  data,
  pageContext: { product, suggestions, articles },
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    i18n.changeLanguage(`fr`);
  }, []);

  const translatedRoutes = routes(language);

  let description = `${t(`metaDescription1`)} ${product.name} ${t(
    `metaDescription2`,
  )} ${product.name} ${t(`metaDescription3`)}.`;

  product.risks.map(
    // eslint-disable-next-line no-return-assign
    (risk: { name: any }) =>
      (description = `${description} ${product.name} ${risk.name} ${t(
        `riskMetaDescription`,
      )} ?`),
  );

  return (
    <Layout
      title={`${t(`metaTitle1`)} ${product.name} ${t(`metaTitle2`)}`}
      description={description}
    >
      <Content>
        <Link
          to={translatedRoutes.categories}
          className="underline inline-block mb-8"
        >
          {`<- ${t(`backCategories`)}`}
        </Link>

        <ProductHeader lang={language} product={product} />
        <SimpleImage
          source="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Bugaboo/OaFgjQcz/banner.webp"
          sourceMobile="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Bugaboo/OaFgjQcz/block.webp"
          alt="Bugaboo Ads"
          link="https://campaign.lafraise.app/esVr"
        />

        {product.risks.length > 0 && (
          <div className="mt-12">
            <h3 className="mb-8">{t(`risksTitle`)}</h3>

            <div className="flex flex-row flex-wrap justify-start gap-6">
              {product.risks.map((risk, index) => (
                <Link
                  key={index}
                  to={`${translatedRoutes.risks}#${slugify(risk.name)}`}
                  className="p-5 rounded-full custom-shadow hover:pointer border-black border-2"
                >
                  {risk.name}
                </Link>
              ))}
            </div>
          </div>
        )}

        <div className="mt-12 lg:mb-24 mb-12">
          <h3 className="mb-8">{t(`relatedFoodsTitle`)}</h3>

          <div className="flex lg:flex-row flex-col justify-between m-auto gap-8">
            {!!suggestions &&
              suggestions.map((suggestion, index) => (
                <Link
                  to={`${translatedRoutes.products}/${slugify(
                    suggestion.name,
                  )}`}
                  key={index}
                >
                  <div className="custom-shadow rounded-3xl overflow-hidden">
                    <img
                      src={suggestion.image.url.split(`?`)[0]}
                      alt={suggestion.name}
                    />

                    <div className="w-full h-16 bg-white flex flex-col justify-center px-6">
                      <p className="text-black text-lg font-bold">
                        {suggestion.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>

        <div className="m-auto lg:my-24 my-8">
          <Articles articles={articles} title={t(`articlesTitle`)} />
        </div>
      </Content>

      <SearchBloc
        source={data.strawberry}
        alt="Fraises"
        placeholder='Essayez "fraise"'
      />
    </Layout>
  );
};

export default ProductTemplate;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["general", "product"] }, language: { eq: "fr" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strawberry: file(relativePath: { eq: "strawberry.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
